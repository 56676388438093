import {
  Box,
  Button,
  chakra,
  FormLabel,
  Input,
  LightMode,
  Stack,
} from "@chakra-ui/react"
import React, { useState, useContext } from "react"

import { ApiContext } from "../shared/Wrapper"
import { AnalyticsEvents, trackEvent } from "../../services/analytics"
import { LandingTypes } from "./landing_types"

export const InvitationForm = () => {
  const api = useContext(ApiContext)
  const [email, setEmail] = useState<string | undefined>(undefined)

  const submitEmail = async () => {
    trackEvent(AnalyticsEvents.LANDING_CLICKED_CTA, {
      email: email,
      landing_type: LandingTypes.BETA_FREE_GET_ACCESS,
    })

    const res = await api.createAccount(email)
    console.log(res)
    if (!!res.error) {
      console.log(res.error.errorMessage)
      return
    }

    api.setApiToken(res.data.token)
  }

  return (
    <LightMode>
      <Stack
        direction={{ base: "column", md: "row" }}
        align={{ md: "flex-end" }}
      >
        <Box flex="1">
          <FormLabel>Enter your email address</FormLabel>
          <Input
            id="email"
            name="email"
            size="lg"
            fontSize="md"
            value={email}
            onKeyDown={e => {
              e.key === "Enter" ? submitEmail() : undefined
            }}
            onChange={e => {
              e.preventDefault()
              setEmail(e.target.value)
            }}
            autoFocus
            bg="white"
            _placeholder={{ color: "gray.400" }}
            color="gray.900"
            placeholder="you@example.com"
            focusBorderColor="blue.200"
          />
        </Box>
        <Button
          onClick={submitEmail}
          size="lg"
          colorScheme="blue"
          fontSize="md"
          px="10"
        >
          Get Access
        </Button>
      </Stack>
    </LightMode>
  )
}
