import { Box, Button, Heading, Stack, Text } from "@chakra-ui/react"
import { Container } from "@chakra-ui/layout"
import React, { useEffect } from "react"
import { AnalyticsPages, trackPage } from "../../services/analytics"
import { VOMUX_PURPLE } from "./colors"
import { Feature } from "./Feature"
import { InvitationForm } from "./InvitationForm"

export const LandingWrapper = () => {
  useEffect(() => {
    trackPage(AnalyticsPages.LANDING)
  }, [])

  return (
    <Box as="section" py={{ lg: "12" }}>
      <Box
        bg={VOMUX_PURPLE}
        h="100vh"
        rounded={{ lg: "2xl" }}
        maxW="5xl"
        mx="auto"
        px={{ base: "4", sm: "6", lg: "8" }}
        py={{ base: "12", sm: "16" }}
      >
        <Box maxW="xl" mx="auto" color="white" textAlign="center">
          <Text
            mb="4"
            fontSize="lg"
            color="whiteAlpha.800"
            fontWeight="semibold"
          >
            Interview engineers like a boss
          </Text>
          <Heading
            mb="3"
            as="h2"
            size="xl"
            fontWeight="extrabold"
            letterSpacing="tight"
          >
            Vomux Collaborative Coding 🚀
          </Heading>
          <Text
            mb="8"
            fontSize="md"
            color="whiteAlpha.800"
            fontWeight="semibold"
          >
            Free for a limited time!
          </Text>
          <InvitationForm />
          <Stack
            spacing={{ base: "3", md: "6" }}
            direction={{ base: "column", md: "row" }}
            mt="4"
            justify="center"
            align="center"
          >
            <Feature>Code together in real-time</Feature>
            <Feature>Unlimited free interviews while we're in beta</Feature>
          </Stack>
        </Box>
        <Container centerContent>
          <Text mt="8" fontSize="md" color="white" fontWeight="semibold">
            Already have access?
          </Text>
          <Button
            onClick={() => {
              console.log("send sign in email")
            }}
            size="lg"
            mt="4"
            colorScheme="blue"
            fontSize="md"
            px="10"
          >
            Click Here to Sign In
          </Button>
        </Container>
      </Box>
    </Box>
  )
}
