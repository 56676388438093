import React from "react"
import { Box } from "@chakra-ui/layout"
import { Context } from "react"
import { Api } from "../../services/api"
import { createContext } from "react"
import axios from "axios"

export const ApiContext: Context<Api> = createContext(
  new Api(
    axios,
    `${process.env.GATSBY_API_SECURITY_PREFIX}${process.env.GATSBY_API_HOST}`
  )
)

export const Wrapper: React.FC = props => {
  return <Box {...props}>{props.children}</Box>
}
