import React from "react"

import { Wrapper } from "../shared/Wrapper"
import { LandingWrapper } from "./LandingWrapper"

const Landing: React.FC = props => {
  return (
    <Wrapper>
      <LandingWrapper />
    </Wrapper>
  )
}

export default Landing
