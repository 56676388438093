import React, { useEffect } from "react"
import Landing from "../components/landing"
import { AnalyticsPages, trackPage } from "../services/analytics"

const LandingPage = () => {
  useEffect(() => {
    trackPage(AnalyticsPages.LANDING)
  }, [])
  return <Landing />
}

export default LandingPage
