declare global {
  interface Window {
    analytics: any
  }
}

export const trackPage = (pageName: string) => {
  if (!window.analytics) {
    console.log("no analytics")
    return
  }

  window.analytics.page(pageName)
}

export const trackEvent = (eventName: string, eventProps: object) => {
  if (!window.analytics) {
    console.log("no analytics")
    return
  }

  window.analytics.track(eventName, eventProps)
}

export enum AnalyticsPages {
  LANDING = "Landing",
}

export enum AnalyticsEvents {
  LANDING_CLICKED_CTA = "Landing : Clicked CTA",
}
